import { MergeObjects } from './../core/library/objects';
import MediaSources from './media_sources';
import * as Projects from './projects';
import {parseUrlParams} from "../core/library/url";
import PushRegions from './regions';

const PROJECT = process.env.PROJECT || "";


let Config = {
    LOCALE: "ru",
    PROJECT_PREFIX: "test",
    RESIZER: false,
    // project config
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `http://localhost:8086/api/backend`,
    },

    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: `https://oauth.absatz.media`,
        // current project domain
        PROJECT_DOMAIN: `localhost:3000`,
        // current project url path
        PROJECT_PATH: `http://localhost:3000`,
        // path for oauth api
        API: `https://oauth.absatz.media/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://oauth.absatz.media/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },
    // default date format
    DATE_FORMAT: "DD-MM-YYYY HH:mm",
    // configuration for slug plugin
    SLUG: {
        delimiter: '-',
        prefix: ''
    },

    // configuration for media
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://cdni.absatz.media/images",
            VIDEO: "https://cdni.absatz.media/video"
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
    },

    DOCUMENTS: {
        STATUS: ["published", "unpublished", "prepublished_status"],
        MOVE_TYPES: ['News', 'Article', "Meaning", "Embargo", 'Receipt'],
    },
    EDITOR: {
        KEY: "clzzivqs4p1bb1eoei67nmbzwwzio5uv0w69ew52cc8ex5s7",
        SETTINGS: {
            language: 'ru',
        },
    },
    MAPS: {
        KEY: "e70f3b03-0677-45b8-911f-830daf383e37",
    },
    ELASTIC: {
        API: "http://localhost:8009/elastic"
    },
    MEDIA_DATABASE_TYPES: ['Image', 'Video', 'ExternalVideo', 'Code'],
    MEDIA_AVAILABLE_TYPES: '.jpg,.gif,.png,.jpeg,.mp4,.mp3',
    MEDIA_AVAILABLE_SOURCES: ['youtube', 'vimeo', 'facebook'],
    WIDGETS: {
        TYPES: ['Article', 'News', 'Episode', 'Infographic','Meaning', 'Trend', 'Banner', 'Show', 'Polls', 'Receipt', 'Quiz']
    },
    REGIONS: PushRegions,
    PHOTO_AUTHORS: [
        'Фото © Имя Фамилия / Агентство-партнер / ТАСС',
        'Фото © Telegram / Название',
        'Фото © VK / Название',
        'Фото © Wikipedia / автор',
        'Фото © Freepik',
        'Фото © сайт / автор',
        'Фото © Фотограф / Абзац',
        'Фото © Google Maps',
        'Фото © kremlin.ru',
        'Фото © president.gov.ua',
        'Фото © Instagram (признан экстремистской организацией и запрещён на территории Российской Федерации) / Название страницы',
        'Фото © Facebook (признан экстремистской организацией и запрещён на территории Российской Федерации) / Название страницы',
        'Фото © YouTube / Название канала',
        'Фото © X.com / автор',
        'Фото © Livejournal / Название страницы',
        'Фото © Дмитрий Дубинский  / Абзац',

        'Фото © Анастасия Романова / Абзац',
        'Фото © Telegram / Суды общей юрисдикции города Москвы',
        'Фото © Telegram / Абзац',
        'Фото © Telegram / Zelenskiy / Official',
        'Фото © Telegram / МЧС России',
        'Фото © АГН «Москва» / автор',
        'Фото © duma.gov.ru',
        'Фото © mil.ru',
        'Фото © council.gov.ru',
        'Фото © mchs.gov.ru',
        'Фото © Росконгресс / автор',
        'Фото © flickr.com / автор',
        'Фото © «Название фильма» режиссёр Имя Фамилия / Kinopoisk.ru',
    ],
    SPECIAL_PROJECT_TYPES: ['Фотовыставка', 'Спец. репортаж', 'Выставка', 'Специальный материал'],
    CROPPER: {
        WIDTH: 1200,
        HEIGHT: 800,
    }
};

if(Projects[PROJECT]) {
    Config = MergeObjects(Config, Projects[PROJECT]);
}

window.APP_CONFIG = {
    PROJECT: Config.PROJECT,
    MEDIA: Config.MEDIA,
};


const MediaPrefixPath = {
    'Cover': Config.MEDIA.CDN.IMAGES,
    'Image': Config.MEDIA.CDN.IMAGES,
    'Audio': Config.MEDIA.CDN.AUDIO,
    'Video': Config.MEDIA.CDN.VIDEO,
};

export const GetImagePath = (args, size = '320', isNewEnc = false) => {
    let files = args.files || args;


    if (!files || !files['original'] || !files['original'].path) {
        return '/images/nocover.png';
    }

    if (isNewEnc) {
        return GetMediaSourcePath(args, size);
    }

    let path = files['original']['path'].split('/');
    if (Config.MEDIA.CDN.IMAGES.indexOf('resizer') + 1 > 0 || Config.MEDIA.CDN.IMAGES.indexOf('cdnimages') + 1 > 0) {
        path[path.length - 1] = `${size}_${path[path.length - 1]}`;
    }
    return `${Config.MEDIA.CDN.IMAGES}/${path.join('/')}`;
    // return `https://cdni.vm.ru/${path.join('/')}`;
}

export const isWebpSupported = () => {
    const el = document.createElement('canvas');
    if (!!(el.getContext && el.getContext('2d'))) {
        return el.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
}


export const WebpSupported = isWebpSupported();

export const GetMediaSourcePath = (media, size = '320') => {
    if (!media || !media.files || !media.files['original'] || !media.files['original'].path) {
        return false;
    }
    let mediaPrefix = MediaPrefixPath[media.type] || Config.MEDIA.CDN.IMAGES;
    let params = media.files['original'].path.split('.');
    if ((media.type === 'Image' || media.type === 'Cover') && WebpSupported) {
        params[params.length - 1] = 'webp';
    }
    return `${mediaPrefix}/${params.join('/')}?w=${size}`;
}


export default Config;


